import { Log } from '@retorio/sdk'
import { ReactElement } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

// only temporary
const isDev = process.env.NODE_ENV === 'development'

class IntlKeyError extends Error {
  constructor(message) {
    super(message)
    this.name = 'IntlKeyError'
  }
}

export type LanguageDictionary = {
  [key: string]: string
}

export type MessageValues = Record<string, number | string | Date | ReactElement>

export type FormattedMessageProps<T extends LanguageDictionary = LanguageDictionary> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  id: keyof T | String
} & Exclude<MessageDescriptor, 'id'> & {
    values?: MessageValues
  }

export const getDefaultMessage = (
  enDict: LanguageDictionary,
  key: FormattedMessageProps['id']
) => {
  // log error to console and/or report to Sentry
  if (!enDict[key] && isDev) {
    Log.error(new IntlKeyError(`${key} doesn't exist in fallback ENG json file`), {
      extra: {
        key,
      },
      fingerprint: ['intl-error'],
    })
  }

  return enDict[key]
}

export const createFormattedMessage =
  <T extends LanguageDictionary>(enDict: T) =>
  /**
   * Only allow `id` values available in the given
   */
  ({ id, description, values }: FormattedMessageProps<T>) => (
    <FormattedMessage
      id={id}
      defaultMessage={getDefaultMessage(enDict, id)}
      description={description}
      // We always allow `lineBreak` placeholder
      values={{
        ...values,
        lineBreak: <br />,
        br: <br />,
        b: chunks => <b>{chunks}</b>,
        i: chunks => <i>{chunks}</i>,
      }}
    />
  )
